p, h3, h1{
    font-family: monospace;
}

.main-background{
        display: flex;
        height:800px;
        flex-direction: column;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        background-size: cover;
}

.logo{
max-width: 75vh;
width: 80%;
}

.flex-item {
    text-align: center;
    flex: 1;
    margin: 8px;
    padding: 4px;
}

.song-lyrics, .song-melody, .song-caption{
    color: black;
    text-align: center;
margin-top: 10px;}

.song-melody{
    font-weight: bold;
}

.song-lyrics{
    display: flex; 
    justify-content: center;
    white-space: pre-wrap;
}

.navbar-title, .navbar-brand, a{
    font-family: monospace;
    font-size: 0.7em!important;
}

.nav-link{
    font-size: 2em!important;
}



.accordion-header{
    font-family: monospace;
}

.accordion-body{
    font-family: monospace;
}


.vrå-link{
    color: white;
    font-family: 'Century Gothic';
    text-decoration: bold;
}

.vrå-map{
    size: 120%;
}

.google-maps{
    text-size: 200%;

}
